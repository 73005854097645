<template>
    <div style="margin:0 10px">
        <router-view></router-view> 
        <div class="card">
            <!-- search -->
            <div class="search d-flex a-center j-sb">
                <div class="d-flex a-center">
                    <el-button type="primary" @click="$router.push({name:'menus_add'})">+ 添加</el-button>    
                </div>

            </div>

            <!-- handle -->
            <div class="handle"></div>

            <!-- table -->
            <div class="table" style="margin-top:20px;">
                <el-table
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px;"
                    row-key="id"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                        <el-table-column prop="id" label="ID" width="180"></el-table-column>
                        <el-table-column prop="title" label="菜单名称"></el-table-column>
                        <el-table-column prop="icon" label="图标">
                            <template slot-scope="scope">
                                <img :src="scope.row.icon" />
                            </template>
                        </el-table-column>
                        <el-table-column prop="menuSort" label="排序"></el-table-column>

                        <el-table-column prop="" label="操作" width="180" align="right">
                            <template slot-scope="scope">
                                <el-button v-if="scope.row.type!=3" type="text" @click="$router.push({name:'menus_add',query:scope.row})">添加</el-button>
                                <el-button type="text" @click="$router.push({name:'menus_edit',query:scope.row})">编辑</el-button>
                                <el-popconfirm
                                    title="是否确定删除？"
                                    confirm-button-text="确认删除"
                                    @confirm="handleDel(scope.row)"
                                >
                                    <el-button style="margin-left: 10px;" slot="reference" type="text">删除</el-button>
                                </el-popconfirm>
                            </template>
                        </el-table-column>
                </el-table>

            
            </div>
            
        </div>
    </div>
</template>

<script>
import {tree,del} from '@/request/api/menus'
export default {
    data(){
        return {
            tableData: [],
            loading:false,
        }
    },
    watch:{
        $route(val ,old){
            //返回刷新
            if(val.name=='menus') {
                this.init()
            }
        }
    },
    created(){
        this.init()
    },

    methods:{
        async init(){
            let {code,data} = await tree()
            if(code!=0) return;
            this.tableData = data

        },

        //删除
        async handleDel(row){
            let {code,msg} = await del({ids:[row.id]})
            if(code!=0) return this.$message.error(msg)
            this.$message.success(msg)
            this.init();
        }
    }
}
</script>


<style scoped lang="less">


</style>

